import React from 'react'
import FileUploadCard from '../fileUploadCard'
import DialogFooter from '../dialogFooter'
import { Dialog } from 'primereact/dialog'
import Card from '../card'
import * as popUp from '../toastr'
import ConstantsUtil from '../../context/constantsUtil'
import { Button } from 'primereact/button'
import GeneralServices from '../../app/service/generalServices'
import FichaBancariaService from '../../app/service/conciliacaoBancaria/fichaBancariaService'
import HandleErrorService from '../../app/service/handleErrorService'
import FichaBancariaTable from './fichaBancariaTable'
import LancarFechamentoBancarioService from '../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import InputChooseContaBancaria from './inputChooseContaBancaria'
import TooltipButton from '../tooltipButton'


class ImportacaoOFXDialog extends React.Component {

    constructor(){
        super()
        this.fichaBancariaService = new FichaBancariaService()
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService()
    }

    state = {
        completedUpload: false,
        fichaBancaria : {},

        loading: false,
    }

    onFinishUpload = (progressKey) => {
        this.fichaBancariaService.getUploadExtratoOFXResult(progressKey)
        .then(response =>{
            // console.log("onFinishUpload: ", response.data)
            this.setState({fichaBancaria: response.data})
            this.setState({completedUpload: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    hideDialog = () => {
        this.setState({completedUpload: false})
        this.setState({fichaBancaria: {}})
        this.props.hideDialog()
    }

    handleSelectionContaBancariaChange = async (selectedContaBancaria, selectedContaBancariaLabel) => {
        let fichaBancaria = this.state.fichaBancaria
        fichaBancaria.contaBancaria = selectedContaBancaria
        fichaBancaria.fichaBancariaList.forEach(item => item.contaBancaria = selectedContaBancaria)
        await this.setState({fichaBancaria})
    }

    editLancamento = async (lancamentoToEdit) => {
        let fichaBancaria = JSON.parse(JSON.stringify(this.state.fichaBancaria))
        let array = JSON.parse(JSON.stringify(fichaBancaria.fichaBancariaList))
        // console.log("array inicial: ", array)
        const index = array.findIndex(item => item.ofxSequence === lancamentoToEdit.ofxSequence)
        // console.log("array[index]: ", array[index])
        // console.log("lancamentoToEdit: ", lancamentoToEdit)
        // console.log("index: ", index)
        this.atualizaValores(array[index], lancamentoToEdit)
        // console.log("array final: ", array)
        // console.log("state inicial: ", this.state.fichaBancaria)
        fichaBancaria.fichaBancariaList = JSON.parse(JSON.stringify(array))
        await this.setState({fichaBancaria})
        // console.log("state final: ", this.state.fichaBancaria)        
    }

    atualizaValores = (arrayElement, lancamentoToEdit) => {
        // console.log("atualizaValores: ", lancamentoToEdit)
        arrayElement.data = lancamentoToEdit.dataCredito
        arrayElement.historico = ConstantsUtil.virgulaEspaco + lancamentoToEdit.complementoHistorico
        
        arrayElement.tipoLancamentoBancario = lancamentoToEdit.tipoDeLancamentoBancario
        arrayElement.tipoDeLancamentoBancario = lancamentoToEdit.tipoDeLancamentoBancario
        arrayElement.tipoDeLancamentoCompleto = lancamentoToEdit.tipoDeLancamentoCompleto

        arrayElement.gravarEscolha = lancamentoToEdit.gravarEscolha

        if(lancamentoToEdit.tipoDeLancamentoBancario === ConstantsUtil.lancamentoBancarioRecebimentoLabel){
            arrayElement.saida = 0
            arrayElement.idTipoDePagamento = null
            arrayElement.tipoFichaBancaria = ConstantsUtil.tipoFichaBancariaLancamentoBancarioLabel

            arrayElement.entrada = lancamentoToEdit.valor
            arrayElement.idTipoDeRecebimento = lancamentoToEdit.tipoDeRecebimento.id

            if(lancamentoToEdit.tipoDeRecebimento.nomeTipoDeRecebimento){
                arrayElement.descricao = lancamentoToEdit.tipoDeRecebimento.nomeTipoDeRecebimento
            }
        }

        else if(lancamentoToEdit.tipoDeLancamentoBancario === ConstantsUtil.lancamentoBancarioPagamentoLabel){
            arrayElement.saida = lancamentoToEdit.valor
            arrayElement.idTipoDePagamento = lancamentoToEdit.tipoDePagamento.id
            arrayElement.tipoFichaBancaria = ConstantsUtil.tipoFichaBancariaLancamentoBancarioLabel

            if(lancamentoToEdit.tipoDePagamento.nome){
                arrayElement.descricao = lancamentoToEdit.tipoDePagamento.nome
            }

            arrayElement.entrada = 0
            arrayElement.idTipoDeRecebimento = null
        }

        arrayElement.tipoLancamento = ''
        if(arrayElement.tipoDeLancamentoBancario === ConstantsUtil.lancamentoBancarioTransferenciaLabel) {

            arrayElement.tipoLancamento = ConstantsUtil.lancamentoBancarioTransferenciaLabel

            if(arrayElement.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioTransferenciaEntradaLabel){
                arrayElement.tipoLancamentoBancario = ConstantsUtil.lancamentoBancarioRecebimentoLabel
                arrayElement.tipoTransacaoOFX = ConstantsUtil.tipoTransacaoOFXCredit
                arrayElement.descricao = ConstantsUtil.transferenciaRecebidaDeLabel + ' ' + lancamentoToEdit.nomeContaBancariaContrapartida
                arrayElement.entrada = lancamentoToEdit.valor
                arrayElement.saida = null
            }
            else if(arrayElement.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioTransferenciaSaidaLabel){
                arrayElement.tipoLancamentoBancario = ConstantsUtil.lancamentoBancarioPagamentoLabel
                arrayElement.tipoTransacaoOFX = ConstantsUtil.tipoTransacaoOFXDebit
                arrayElement.descricao = ConstantsUtil.transferenciaRealizadaParaLabel + ' ' + lancamentoToEdit.nomeContaBancariaContrapartida
                arrayElement.entrada = null
                arrayElement.saida = lancamentoToEdit.valor
            }

            arrayElement.idContaBancariaOrigemTransferencia = lancamentoToEdit.idContaBancaria
            // arrayElement.idContaBancaria = lancamentoToEdit.idContaBancaria

            arrayElement.idContaBancariaDestinoTransferencia = lancamentoToEdit.idContaBancariaDestino
            // arrayElement.idContaBancariaDestino = lancamentoToEdit.idContaBancariaDestino

            arrayElement.tipoFichaBancaria = ConstantsUtil.tipoFichaBancariaTransferenciaLabel
            arrayElement.idContaBancariaContrapartida = lancamentoToEdit.idContaBancariaContrapartida
            arrayElement.nomeContaBancariaContrapartida = lancamentoToEdit.nomeContaBancariaContrapartida

        }

        // console.log("arrayElement final: ", arrayElement)

    }

    deleteLancamento = async (lancamentoToDelete) => {
        let fichaBancaria = JSON.parse(JSON.stringify(this.state.fichaBancaria))
        let array = JSON.parse(JSON.stringify(fichaBancaria.fichaBancariaList))
        // console.log("array inicial: ", array)
        // console.log("delete: ", lancamentoToDelete)
        const index = array.findIndex(item => item.ofxSequence === lancamentoToDelete.ofxSequence)
        array.splice(index, 1)
        // console.log("array final: ", array)
        // console.log("this.")
        // console.log("state inicial: ", this.state.fichaBancaria)
        fichaBancaria.fichaBancariaList = JSON.parse(JSON.stringify(array))
        await this.setState({fichaBancaria})
        // console.log("state final: ", this.state.fichaBancaria)
    }

    checkData = () => {
        let check = true

        if(!this.state.fichaBancaria.contaBancaria || !this.state.fichaBancaria.contaBancaria.id){
            popUp.warningPopUp(ConstantsUtil.errorSelecioneContaBancariaMessage)
            check = false
        }

        return check;

    }

    callSave = () => {
        if(this.checkData()){
            // console.log("call save: ", this.state.fichaBancaria.fichaBancariaList)
            this.save()
        }
    }

    save = () => {
        this.setState({loading: true})
        // console.log("initial list: ", this.state.fichaBancaria.fichaBancariaList)
        let list = this.montaObjLancamentoBancarioList()
        // console.log("list: ", list)
        this.lancarFechamentoBancarioService.saveListFromOFX(list)
        .then(response => {
            popUp.successPopUp("Lançamentos salvos com sucesso!")
            this.hideDialog()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    montaObjLancamentoBancarioList = () => {
        // console.log("this.state: ", this.state.fichaBancaria)
        let lancamentoList = []
        this.state.fichaBancaria.fichaBancariaList.forEach(item => {

            if(!item.tipoDeLancamentoBancario){
                item.tipoDeLancamentoBancario = item.tipoLancamentoBancario
            }

            if(item.tipoFichaBancaria === ConstantsUtil.tipoFichaBancariaTransferenciaLabel && item.tipoDeLancamentoBancario !== ConstantsUtil.lancamentoBancarioTransferenciaLabel){
                item.tipoDeLancamentoBancario = ConstantsUtil.lancamentoBancarioTransferenciaLabel
                if(item.tipoTransacaoOFX === ConstantsUtil.tipoTransacaoOFXDebit){
                    item.idContaBancariaOrigemTransferencia = item.contaBancaria.id
                    item.idContaBancariaDestinoTransferencia = item.idContaBancariaContrapartida
                }
                else{
                    item.idContaBancariaOrigemTransferencia = item.idContaBancariaContrapartida
                    item.idContaBancariaDestinoTransferencia = item.contaBancaria.id
                }
            }

            let idContaBancaria = item.contaBancaria.id
            let idContaBancariaDestino = null
            if(item.tipoDeLancamentoBancario === ConstantsUtil.lancamentoBancarioTransferenciaLabel){
                idContaBancaria = item.idContaBancariaOrigemTransferencia
                idContaBancariaDestino = item.idContaBancariaDestinoTransferencia
            }

            // console.log("item: ", item)
            const obj = {
                // idLancamento: this.state.idLancamentoFechamentoBancario,
                dataCredito: item.data,
                tipoDeLancamentoBancario: item.tipoDeLancamentoBancario,
                // tipoDeLancamentoBancarioOriginal: item.tipoDeLancamentoBancarioOriginal,
    
                idTipoDeRecebimento: item.tipoDeLancamentoBancario === ConstantsUtil.lancamentoBancarioRecebimentoLabel ? 
                    item.idTipoDeRecebimento : null,
    
                idTipoDePagamento: item.tipoDeLancamentoBancario === ConstantsUtil.lancamentoBancarioPagamentoLabel ? 
                    item.idTipoDePagamento : null,
    
                idContaBancaria: idContaBancaria,
                idContaBancariaDestino: idContaBancariaDestino, //será utilizado pelo backend apenas quando se tratar de transferência

                complementoHistorico: item.historico ? item.historico.split(ConstantsUtil.virgulaEspacoToRegexpSplit)[1] : "",
                // ofxSequence: this.state.ofxSequence,
                valor: item.entrada && item.entrada > 0 ? item.entrada : item.saida,

                descricaoOFX: item.descricaoOFX,
                gravarEscolha: item.gravarEscolha,
                tipoTransacaoOFX: item.tipoTransacaoOFX
    
                /*
                    Os dois abaixo são utilizados pelo backend apenas quando se trata de um lancamento de fechamento bancário
                    do Tipo de Recebimento AVULSO.
                */
                // idContaContabil: parseInt(this.state.selectedContaContabil.numeroDeChamada)/5,
                // historicoAvulso: this.state.historico
            }

            lancamentoList.push(obj)

        })

        // console.log("lancamentoList: ", lancamentoList)

        return lancamentoList
    }

    templateToExport = () => {
        
        let listToExport = [{
            ['data']: '12-11-2024',
            ['descrição']: "Exemplo",
            ['valor']: '1.325,64'
        }]

        return listToExport;
    }

    render(){

        const renderUpload = () => {
            if(!this.state.completedUpload){
                return (
                    <>
                        <div className='col-md-3'>
                            <Button
                                label="Baixar modelo em xlsx (excel)"
                                icon="pi pi-download"
                                className="p-button-success"
                                onClick={() => GeneralServices.exportExcel(this.templateToExport(), "Modelo", "modelo_extrato_bancario")}
                                style={{maxHeight: '30px', marginLeft: '10px'}}
                            />
                            <br />
                            <br />
                        </div>
                        <FileUploadCard
                            uploadInProgress = {this.setIfIsInProgress}
                            accept=".ofx, .pdf, .xlsx"
                            uploadDescription={ConstantsUtil.importacaoExtratoBancarioDescricao}
                            onFinish={this.onFinishUpload}
                        />
                    </>
                )            
            }
        }

        const uploadDialogFooter = (
            <DialogFooter save={this.callSave} hideDialog={this.hideDialog} disabled={!this.state.completedUpload} />
        )

        const renderFichaBancaria = () => {
            if(this.state.completedUpload){
                return (
                    <>
                    <InputChooseContaBancaria
                        label="Conta Bancária"
                        readOnly
                        excludeFundosFixos
                        excludeAplicacaoFinanceira
                        selectedContaBancariaLabel={this.state.fichaBancaria.contaBancaria ? this.state.fichaBancaria.contaBancaria.formattedName : ""}
                        handleSelectionContaBancariaChange={this.handleSelectionContaBancariaChange}
                    />

                    <br />                  

                    <FichaBancariaTable
                        fichaBancaria={this.state.fichaBancaria}
                        insideDialog
                        fromOFX
                        deleteLancamentoOFX={this.deleteLancamento}
                        editLancamentoFromOFX={this.editLancamento}
                        loading={this.state.loading}
                        // isSearchByValue={this.state.isSearchByValue}
                        // tipoDeRecebimentoList={this.state.filteredTipoDeRecebimentoList}
                        // search={this.searchFromFichaBancariaTable}
                        // loading={this.state.loadingTable}
                        // push={this.props.history.push}
                    />
                    </>
                )
            }
        }

        const renderRightTooltipButton = () => {
            return (
                <>
                    <TooltipButton
                        tooltip={
                            "A importação de PDF deve ser realizada apenas para comprovantes de pagamento do Banco Itaú.\n"
                            + "Para os demais casos, utilize a importação em OFX, inclusive para os recebimentos do Itaú.\n"
                            + "Além disso, é possível realizar a importação em XLSX (Excel) para qualquer situação, desde que o arquivo esteja conforme o modelo disponibilizado."
                        }
                        tooltipOptions={{
                            className: 'custom-tooltip',
                            position: 'left'
                        }}
                        icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                        style ={{maxHeight: '25px', maxWidth: '25px'}}
                        className="p-button-rounded p-mr-2"
                    />
                </>
            )
        }

        return (
            <>
                <Dialog
                    visible={this.props.visible}
                    style={{ maxHeight: '95vh', width: '85vw'}}
                    modal
                    className="p-fluid"
                    baseZIndex={-1}
                    footer={uploadDialogFooter}
                    onHide={this.hideDialog}>
                    <div className="p-field">
                    <Card
                        title = {"Importação de Extrato Bancário em OFX, PDF ou XLSX"}
                        rightComponent={renderRightTooltipButton()}
                    >
                        {renderUpload()}
                        {renderFichaBancaria()}
                    </Card>
                    </div>
    
                </Dialog>       
            </>
        )
    }

    
}

export default ImportacaoOFXDialog