import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import currecyFormater from 'currency-formatter'
import * as popUp from '../../components/toastr'
import GeneralServices from '../../app/service/generalServices'
import TableFilters from '../../components/tableFilters'
import TransactionService from '../../app/service/estoque/transactionService'
import ConstantsUtil from '../../context/constantsUtil'
import CheckNFDialog from '../../components/product/checkNFDialog'
import HandleErrorService from '../../app/service/handleErrorService'
import NFService from '../../app/service/estoque/NFService'
import ConfirmationDialog from '../../components/confirmationDialog'
import TooltipButton from '../../components/tooltipButton'
import { Dialog } from 'primereact/dialog'
import EditProvisaoDialog from '../../components/contasAPagar/provisoes/editProvisaoDialog'
import { ProgressSpinner } from 'primereact/progressspinner'

class TransactionByNFTable extends React.Component {

    state = {

        NFList: [],

        selectedNFs: null,
        
        expandedRows: [],
        current: null,
        
        NFToValidate: '',
        checkNFDialogVisible: false,

        NFToDelete: '',
        visibleConfirmDeleteNFDialog: false,

        expandedRows: [],

        NFToReativar: '',
        visibleConfirmReativarNFDialog: false,

        displayMotivoDialog: false,
        nfExlcuidaToShowMotivo: null,
        
        displayNFDevolucaoEntradaDialog: false,
        notaFiscalDevolucaoReferencia: null,

        notaFiscalToEditProvisao: null,
        displayEditProvisaoDialog: false,

    }


    constructor(){
        super()
        this.toast = React.createRef()
        this.dt = React.createRef()
        this.tableFilters = new TableFilters();
        this.transactionService = new TransactionService();
        this.NFService = new NFService();

    }

    componentDidMount(){
        if(this.props.validateNFs){
            this.searchNFsPendentes();
        }

        // console.log("componentDidMount")
        
        this.filtraOcultaExcluidas()

    }

    filtraOcultaExcluidas = async () => {
        if(!this.props.isSearchByNumero){
            GeneralServices.filtraOcultaExcluidas(
                (selectedSituacao) => this.setState({selectedSituacao}),
                'situacaoLabel',
                this.dt
            )
        }
    }


    getList(){
        // console.log("getList")
        if(this.props.validateNFs){
            return this.state.NFList
        }
        else if(this.props.list){
            // console.log("this.props.list: ", this.props.list)
            return this.props.list
        }
    }    

    searchNFsPendentes = () =>{
        this.setState({loading: true})
        this.NFService.getNFsPendentes()
        .then(async response => {
            GeneralServices.dealNFListDates(response.data)
            await this.setState({NFList: response.data})
            if(!response.data.length){
                popUp.infoPopUp("Não há Notas Fiscais a serem validadas.")
            }          
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({disableDeleteButton: false})
        })
        .finally(() => {
            this.resetaFiltrosTable()
            this.setState({loading: false})
        })
    }

    valueBodyTemplate = (rowData) => {
        return currecyFormater.format(rowData.value, {locale: 'pt-BR'})
    }

    hideDialog = () => {
        this.setState({checkNFDialogVisible: false})
    }

    delete = () => {
        if(this.state.selectedNFs){
            this.setState({displayDeleteConfirmation: true})
        } else {
            popUp.warningPopUp("Nenhuma nota foi selecionada para exclusão")
        }
    }


    checkNF = (rowData) => {
        this.setState({checkNFDialogVisible: true})
        this.setState({NFToValidate: rowData})
    }

    validateNF = () => {
        // console.log("NFToValidate: ", this.state.NFToValidate)
        var NFToValidate = this.state.NFToValidate
        NFToValidate.dataHoraEntrada = `${NFToValidate.dataEntrada} - ${NFToValidate.horaEntrada}`

        this.NFService.validateNF(this.state.NFToValidate)
        .then(async response => {
            popUp.successPopUp("Nota Fiscal validada com sucesso!")
            await this.searchNFsPendentes()
            await this.setState({NFToValidate: ''})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        
    }    

    callExcluirNF = (rowData) => {
        this.setState({visibleConfirmDeleteNFDialog: true})
        this.setState({NFToDelete: rowData})
    }

    deleteNF = (motivoExclusao, gravarFrase) => {
        this.setState({loading: true})
        this.setState({visibleConfirmDeleteNFDialog: false})
        this.state.NFToDelete.motivoExclusao = motivoExclusao
        this.NFService.deleteNF(this.state.NFToDelete, gravarFrase)
        .then(async response => {
            popUp.successPopUp("Nota Fiscal excluída com sucesso!")
            if(this.props.validateNFs){
                await this.searchNFsPendentes()
            }
            else{
                await this.props.search()
                this.resetaFiltrosTable()
            }
            await this.setState({NFToDelete: ''})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    resetaFiltrosTable = () => {
        this.setState({selectedNumeroNF: ''})
        this.setState({selectedDataEmissaoNF: ''})
        this.setState({selectedDataEntradaNF: ''})
        this.setState({selectedDataImportacaoNF: ''})
        this.setState({selectedFornecedorOuCliente: ''})
        this.setState({selectedvalorTotalNF: ''})
        this.setState({selectedTipoNF: ''})
        this.setState({selectedModeloNF: ''})
        this.setState({selectedChaveNF: ''})
        this.setState({selectedSituacao: ''})
        this.dt.current.reset()

    }

    callRevalidarNF = (rowData) => {
        this.setState({visibleConfirmReativarNFDialog: true})
        this.setState({NFToReativar: rowData})
    }

    revalidarNF = () => {
        this.setState({loading: true})
        this.setState({visibleConfirmReativarNFDialog: false})
        this.NFService.revalidarNF(this.state.NFToReativar)
        .then(async response => {
            popUp.successPopUp("Nota Fiscal reativada com sucesso!")
            await this.props.search()
            await this.setState({NFToReativar: ''})
            this.resetaFiltrosTable()
        }).catch(error => {
            HandleErrorService.handleError(error)
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    handleRowToggle = (e) => {
        this.setState({expandedRows: e.data})
        if(!this.state.current) {
            this.setState({current: this.dt.current})
        }
        if(!this.dt.current) {
            this.dt.current = this.state.current
        }
    }

    calculateQuantidadeAndValorTotal = (currentTableValue) => {
        // console.log("calculateQuantidadeAndValorTotal: ", currentTableValue)
        if(this.props.setInfo){
            const quantidade = currentTableValue ? currentTableValue.length : 0
            
            let valorTotal = 0
            if(currentTableValue){
                currentTableValue.forEach(item => valorTotal+= item.valorTotal)
            }
    
            this.props.setInfo({quantidade, valorTotal})
        }

        if(this.props.onTableFilter){
            this.props.onTableFilter(currentTableValue)
        }

    }

    hidePesquisaNumeroDialog = () => {
        this.setState({pesquisaNumeroDialogVisible: false})
        // this.setState({inputSearchValueErrorClass: ''})
        // this.setState({errorSearchValueMessage: ''})
    }

    openMotivoDialog = async (rowData) => {
        await this.setState({displayMotivoDialog: true})
        await this.setState({nfExlcuidaToShowMotivo: rowData})
        // console.log("openMotivoDialog: ", this.state)
    }

    hideMotivoDialog = () => {
        this.setState({displayMotivoDialog: false})
        this.setState({nfExlcuidaToShowMotivo: ''})
    }

    openDadosDevolucaoEntradaDialog = async (rowData) => {
        /*
        Renderiza um dialog com os dados da devolução.
        Pode ser a partir de uma NF de Devolução ou mesmo a partir de uma NF de Entrada que tenha sido devolvida,
        seja parcial ou totalmente.
        */
        await this.setState({displayNFDevolucaoEntradaDialog: true})
        this.searchDadosDevolucao(rowData)
    }

    
    searchDadosDevolucao = (notaFiscal) => {
        this.NFService.searchDadosDevolucao(notaFiscal)
        .then(response => {
            this.setState({notaFiscalDevolucaoReferencia: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    montaFraseDadosDevolucao = () => {
        let nfDevolvida = this.state.notaFiscalDevolucaoReferencia.nfDevolvida
        if(nfDevolvida){
            return `N° ${nfDevolvida.numero}, chave ${nfDevolvida.chave}.`
        }
        return ""
    }
    
    hideNFDevolucaoEntradaDialog = () => {
        this.setState({displayNFDevolucaoEntradaDialog: false})
        this.setState({notaFiscalDevolucaoReferencia: ''})
    }

    editProvisao = async notaFiscal => {
        
        if(notaFiscal.tipo === ConstantsUtil.saidaLabel){
            popUp.infoPopUp("Nota Fiscal de Saída não possui pagamento.")
            return;
        }

        //Verifica se é NF de Devolução de Entrada. Não possui provisão de pagamento.
        if(notaFiscal.tipo === ConstantsUtil.tipoNFDevolucaoEntradaLabel){
            popUp.infoPopUp("Nota Fiscal de Devolução de Entrada não possui pagamento.")
            return;
        }

        //Verifica se NF de Entrada com Bonificação Total. Se for, não possui provisão de pagamento.
        this.NFService.isBonificacaoTotal(notaFiscal)
        .then(async response => {
            if(response.data){
                //resposta veio true, ou seja, é bonificação total.
                popUp.infoPopUp("Nota Fiscal de Entrada com Bonificação total não possui pagamento.")
            }
            else{
                await this.setState({notaFiscalToEditProvisao: notaFiscal})
                this.setState({displayEditProvisaoDialog: true})
            }
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    hideEditProvisaoDialog = () => {
        this.setState({notaFiscalToEditProvisao: null})
        this.setState({displayEditProvisaoDialog: false})
    }

    rowClass(rowData, product) {
        // console.log("rowData: ", rowData)
        // console.log("product: ", product)
        let isProdutoDaFichaDeEstoque = false

        if(product){
            if(product.uuid){
                isProdutoDaFichaDeEstoque = (product.uuid === rowData.productInfo.uuid)
            }
            else{
                isProdutoDaFichaDeEstoque = (product.id === rowData.productInfo.id)
            }
        }

        return {
            [ConstantsUtil.totaisTableLabel]: isProdutoDaFichaDeEstoque
        }
    }

    render (){

        const renderMotivoDialog = () => {
            return (
                <Dialog
                    header={"Motivo da Exclusão da NF"}
                    visible={this.state.displayMotivoDialog}
                    modal
                    style={{ width: '50vw' }}
                    onHide={this.hideMotivoDialog}
                >

                    <div className="confirmation-content" >
                        <div className='row centraliza-conteudo-div'>
                        <div style={{marginBottom: '10px'}}> {this.state.nfExlcuidaToShowMotivo ? this.state.nfExlcuidaToShowMotivo.motivoExclusao : ""} </div>
                        </div>
                        
                    </div>
        
                </Dialog>                 
            )
        }

        const renderNFDevolucaoEntradaDialog = () => {
            if(!this.state.notaFiscalDevolucaoReferencia){
                return (
                    <Dialog
                        header={"Dados da Devolução"}
                        visible={this.state.displayNFDevolucaoEntradaDialog}
                        modal
                        style={{ width: '50vw' }}
                        // onHide={this.hideNFDevolucaoEntradaDialog}
                    >
                        <div className='row centraliza-conteudo-div'>
                            <ProgressSpinner />
                        </div>
                </Dialog>          
                )
            }
            return (
                <Dialog
                    header={"Dados da Devolução"}
                    visible={this.state.displayNFDevolucaoEntradaDialog}
                    modal
                    style={{ width: '70vw' }}
                    onHide={this.hideNFDevolucaoEntradaDialog}
                >

                    <div className="confirmation-content" >
                        {/* <div className='row centraliza-conteudo-div'> */}
                        <div className='row '>
                        <div
                            style={{marginBottom: '10px'}}
                        >
                            <li>
                                <strong>Nota Fiscal Devolvida: </strong>
                                {this.montaFraseDadosDevolucao()}
                            </li>

                            <li>
                                <strong>Nota Fiscal de Devolução: </strong>
                                {`N° ${this.state.notaFiscalDevolucaoReferencia.nfDevolucao.numero}, chave ${this.state.notaFiscalDevolucaoReferencia.nfDevolucao.chave}.`}
                            </li>
                            
                            <li>
                                <strong>Informação Complementar: </strong>
                                {this.state.notaFiscalDevolucaoReferencia.nfDevolucao.informacaoComplementar}
                            </li>

                            <li>
                                <strong>Tipo de Devolução: </strong>
                                {this.state.notaFiscalDevolucaoReferencia.tipoDevolucao}
                            </li>
                        </div>
                        </div>
                        
                    </div>
        
                </Dialog>         
            )
        }

        //numeroNFFilterElement Filter
        const numeroNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNumeroNF,
            this.getList(),
            "selectedNumeroNF",
            (name, value) => this.setState({[name]: value}),
            'numero',
            '100%',
            "Número"
        )
        
        //dataEmissaoNF Filter
        const dataEmissaoNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataEmissaoNF,
            this.getList(),
            "selectedDataEmissaoNF",
            (name, value) => this.setState({[name]: value}),
            'dEmiSemHorario',
            '100%',
            "Data Emissão"
        )        
        
        //dataEntradaNF Filter
        const dataEntradaNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataEntradaNF,
            this.getList(),
            "selectedDataEntradaNF",
            (name, value) => this.setState({[name]: value}),
            'dataEntradaSemHorario',
            '100%',
            "Data Entrada"
        )        
        
        //dataImportacaoNF Filter
        const dataImportacaoNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataImportacaoNF,
            this.getList(),
            "selectedDataImportacaoNF",
            (name, value) => this.setState({[name]: value}),
            'dataImportacaoSemHorario',
            '100%',
            "Data Importação"
        )        


        //FornecedorOuCliente Filter
        const fornecedorOuClienteFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedFornecedorOuCliente,
            this.getList(),
            "selectedFornecedorOuCliente",
            (name, value) => this.setState({[name]: value}),
            'nomeFornecedorOuCliente',
            '100%',
            "Nome"
        )
        
        //valorTotalNFFilterElement Filter
        const valorTotalNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedvalorTotalNF,
            this.getList(),
            "selectedvalorTotalNF",
            (name, value) => this.setState({[name]: value}),
            'valorTotal',
            '100%',
            "Valor Total"
        )


        //situacaoFilterElement Filter
        const situacaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedSituacao,
            this.getList(),
            "selectedSituacao",
            (name, value) => this.setState({[name]: value}),
            'situacaoLabel',
            '50px',
            "Situação"
        )

        //tipoNFFilterElement Filter
        const tipoNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoNF,
            this.getList(),
            "selectedTipoNF",
            (name, value) => this.setState({[name]: value}),
            'tipo',
            '50px',
            "Tipo"
        )

        //tipoNFFilterElement Filter
        const tipoCriacaoNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoCriacaoNF,
            this.getList(),
            "selectedTipoCriacaoNF",
            (name, value) => this.setState({[name]: value}),
            'tipoCriacao',
            '50px',
            "Tipo de Lançamento"
        )

        //modeloNFFilterElement Filter
        const modeloNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedModeloNF,
            this.getList(),
            "selectedModeloNF",
            (name, value) => this.setState({[name]: value}),
            'modelo',
            '55px',
            "Modelo"
        )

        //chaveNFFilterElement Filter
        const chaveNFFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedChaveNF,
            this.getList(),
            "selectedChaveNF",
            (name, value) => this.setState({[name]: value}),
            'chave',
            '50px',
            "Chave"
        )

        //quantidadeProdutoNaNotaFilterElement Filter
        const quantidadeProdutoNaNotaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedQuantidadeProdutoNaNota,
            this.getList(),
            "selectedQuantidadeProdutoNaNota",
            (name, value) => this.setState({[name]: value}),
            'quantidadeProdutoNaNota',
            '100%',
            "Quantidade"
        )

        //valorUnitarioProdutoNaNotaFilterElement Filter
        const valorUnitarioProdutoNaNotaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorUnitarioProdutoNaNota,
            this.getList(),
            "selectedValorUnitarioProdutoNaNota",
            (name, value) => this.setState({[name]: value}),
            'valorUnitarioProdutoNaNota',
            '100%',
            "Valor"
        )

        //valorTotalProdutoNaNotaFilterElement Filter
        const valorTotalProdutoNaNotaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorTotalProdutoNaNota,
            this.getList(),
            "selectedValorTotalProdutoNaNota",
            (name, value) => this.setState({[name]: value}),
            'valorTotalProdutoNaNota',
            '100%',
            "Valor"
        )

        //nomeEmpresaFilterElement Filter
        const nomeEmpresaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNomeEmpresa,
            this.getList(),
            "selectedNomeEmpresa",
            (name, value) => this.setState({[name]: value}),
            'empresa.nome',
            '100%',
            "Empresa"
        )

        const rowExpansionTemplate = rowData => {
            // console.log("expansionRowData", rowData)
            return(
                <div className="orders-subtable">
                <h5>Itens da nota {rowData.numero}</h5>
                <DataTable
                    // ref={this.dt}
                    value={rowData.transactions}
                    className="p-datatable-sm small-font"
                    rowHover
                    scrollable
                    rowClassName={(rowData) => this.rowClass(rowData, this.props.product)}
                    scrollHeight="300px"
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, rowData.transactions ? rowData.transactions.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Itens"
                    >

                    <Column field="productInfo.codigo" header="Código" style ={ {width: '80px'} }
                    // filter filterElement={codeFilterElement}
                     />

                    <Column field="productInfo.descricao" header="Descrição do Produto"   style ={ {width: '140px'} } 
                    // filter filterElement={descriptionFilterElement}
                    />

                    <Column field="quantidade" header="Quantidade"   style ={ {width: '140px'} }/>

                    <Column field = "valorUnitario" header="Valor Unitário" body={transaction => GeneralServices.valueBodyTemplate(transaction.valorUnitario)}   style ={ {width: '140px'} }></Column>

                    <Column field = "valor" header="Valor Total" body={transaction => GeneralServices.valueBodyTemplate(transaction.valor)}   style ={ {width: '140px'} }></Column>
                    
                    
                </DataTable>
                
                </div>
            )
        }

        const actionValidarNFButton = (rowData) => {
            if(this.props.validateNFs){
                return (
                    <TooltipButton
                        tooltip = "Validar NF"
                        icon="pi pi-check"
                        style={ {maxHeight: '25px', maxWidth: '25px'} }
                        onClick={() => this.checkNF(rowData)} 
                    />
                )
            }
        }

        const actionExcluirNFButton = (rowData) => {
            // console.log("rowData: ", rowData)
            if(
                this.props.validateNFs 
                || 
                (
                  rowData && rowData.situacao === ConstantsUtil.situacaoRegular
                ) 
            ){
                return (
                    <TooltipButton
                        tooltip = {`Excluir Nota Fiscal. \n Essa NF ainda constará no sistema, mas não promoverá nenhuma alteração em Ficha de Estoque.`}
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-mr-2"
                        style={ {maxHeight: '25px', maxWidth: '25px', marginLeft: '5px'} }
                        onClick={() => this.callExcluirNF(rowData)}
                    />
                )
            }
        }

        const actionRevalidarNFButton = (rowData) => {
            if(this.props.conferenciaNF && (rowData.tipo === ConstantsUtil.tipoNFEntradaLabel || rowData.tipo === ConstantsUtil.tipoNFDevolucaoEntradaLabel) )
            {
                return (
                    <TooltipButton
                        tooltip = {`Revalidar Nota Fiscal. \n Essa NF voltará à tela de Validação.`}
                        icon="pi pi-undo"
                        style={ {maxHeight: '25px', maxWidth: '25px', marginLeft: '5px'} }
                        onClick={() => this.callRevalidarNF(rowData)} 
                    />
                )
            }
        }

        const actionBody = (rowData) => {
            const buttons = [
                actionValidarNFButton(rowData),
                actionExcluirNFButton(rowData),
                actionRevalidarNFButton(rowData),
            ];
        
            // Retorna null se nenhum botão for renderizado, caso contrário, retorna os botões
            for(let button of buttons){
                if(button !== undefined){
                    return buttons
                }
            }
            return null
        }

        const renderAcoesColumn = () => {
            // const actionButtons = actionBody()
            // if(actionButtons){
                return (
                    <Column header="Ações" body={actionBody} style ={ {width: '45px'} } />
                )
            // }
        }

        const renderDataEntradaColumn = () => {
            if(this.props.tipoNF === ConstantsUtil.tipoNFEntradaLabel){
                return (
                    <Column
                        header="Data Entrada"
                        field="dataEntradaSemHorario"
                        body={rowData => rowData.dataHoraEntrada ? rowData.dataHoraEntrada.substring(0, 21) : ''}
                        style ={ {width: this.props.insideFichaDeEstoqueDetalhamento ? '30px' : '60px'} }
                        sortable
                        sortField='dataEntradaJsDate'
                        filter filterElement={dataEntradaNFFilterElement}
                    />
                )
            }
        }

        const actionBodyProvisao = (notaFiscal) => {
            if(notaFiscal.situacaoLabel === ConstantsUtil.situacaoRegularValidadaLabel){
                return (
                    <React.Fragment>
                        <TooltipButton
                            tooltip='Dados de Pagamento'
                            tooltipOptions={{ position: 'right' }}
                            icon={<i className="pi pi-pencil" style={{fontSize: '1em'}}></i>}
                            onClick={() => this.editProvisao(notaFiscal)}
                            style={{maxHeight: '25px', maxWidth: '25px'}}
                        />
    
                    </React.Fragment>
                );
            }
        }

        const renderColunaDadosPagamentoNF = () => {
            if(!this.props.validateNFs && !this.props.simpleView){
                return (
                    <Column header="Dados de pagamento da NF" body={actionBodyProvisao} style ={ {width: '2.5rem'} }/>
                )
            }
        }

        const renderSimpleView = () => {
            return (
                [
                    <Column
                        header="Data de Emissão"
                        field="dEmiSemHorario"
                        body={rowData => rowData.dhEmi.substring(0, 21)}
                        style ={ {width: this.props.insideFichaDeEstoqueDetalhamento ? '30px' : '60px'} }
                        sortable
                        sortField='dhEmiJsDate'
                        filter filterElement={dataEmissaoNFFilterElement}
                    />,

                    renderDataEntradaColumn(),

                    <Column
                        header="Número"
                        field="numero"
                        style ={ {width: this.props.insideFichaDeEstoqueDetalhamento ? '25px' : '40px'} }
                        sortable
                        filter filterElement={numeroNFFilterElement}
                    />,

                    <Column
                        header={this.props.tipoNF === ConstantsUtil.tipoNFEntradaLabel || this.props.validateNFs ? "Fornecedor" : "Cliente"}
                        field="nomeFornecedorOuCliente"
                        body={ (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.nomeFornecedorOuCliente, rowIndex)}
                        style ={ {width: this.props.insideFichaDeEstoqueDetalhamento ? '30px' : '80px'} }
                        sortable
                        filter filterElement={fornecedorOuClienteFilterElement}
                    />,

                    <Column field = "valorTotal" header="Valor Total"
                        body={NF => GeneralServices.valueBodyTemplate(NF.valorTotal)}
                        style ={ {width: '40px'} }
                        sortable
                        filter filterElement={valorTotalNFFilterElement}
                    />,
                    
                    <Column
                        header="Situação"
                        field="situacaoLabel"
                        sortable
                        style ={ {width: '30px'} }
                        filter filterElement={situacaoFilterElement}
                        body = { (rowData, rowIndex) => GeneralServices.renderStyledSituacaoNF(
                            rowData.situacaoLabel,
                            rowIndex,
                            () => {this.openMotivoDialog(rowData)}
                        )}
                    />,

                    <Column
                        header="Tipo"
                        field="tipo"
                        sortable
                        style ={ {width: '30px'} }
                        filter filterElement={tipoNFFilterElement}
                        body = { (rowData, rowIndex) => GeneralServices.renderStyledTipoNF(
                            rowData,
                            rowIndex,
                            () => {this.openDadosDevolucaoEntradaDialog(rowData)}
                        )}
                    />,
                    <Column
                        header="Chave"
                        field="chave"
                        style ={ {width: '20px'} }
                        body={rowData => GeneralServices.copyChNFeBody(rowData.chave)}
                        filter filterElement={chaveNFFilterElement}
                    />
                ]
            )
        }

        const renderQuantidadeProdutoNaNota = () => {
            if(this.props.insideFichaDeEstoqueDetalhamento){
                return (
                    <Column
                        header="Quantidade"
                        field="quantidadeProdutoNaNota"
                        style ={ {width: '30px'} }
                        sortable
                        filter filterElement={quantidadeProdutoNaNotaFilterElement}
                    />                    
                )
            }
        }

        const renderValorProdutoNaNota = () => {
            if(this.props.insideFichaDeEstoqueDetalhamento){
                return (
                    [
                        <Column
                            header="Vr Unit"
                            field="valorUnitarioProdutoNaNota"
                            style ={ {width: '30px'} }
                            sortable
                            filter filterElement={valorUnitarioProdutoNaNotaFilterElement}
                        />,
                        <Column
                            header="Vr Total"
                            field="valorTotalProdutoNaNota"
                            style ={ {width: '30px'} }
                            sortable
                            filter filterElement={valorTotalProdutoNaNotaFilterElement}
                        />
                    ]
                )
            }
        }

        const renderConversao = () => {
            if(this.props.insideFichaDeEstoqueDetalhamento && this.props.tipoDetalhamento === ConstantsUtil.entradaLabel){
                return (
                    <Column
                        header="Conversão"
                        field="conversao"
                        style ={ {width: '30px'} }
                        // sortable
                        // filter filterElement={tipoCriacaoNFFilterElement}
                    />                    
                )
            }
        }

        const renderRemainderView = () => {
            if(!this.props.simpleView){
                return (
                    [
                        <Column
                            header="Tipo de Lançamento"
                            field="tipoCriacao"
                            sortable
                            style ={ {width: '60px'} }
                            filter filterElement={tipoCriacaoNFFilterElement}
                        />,

                        <Column
                            header="Fonte"
                            field="fonte"
                            sortable
                            style ={ {width: '40px'} }
                            filter filterElement={tipoCriacaoNFFilterElement}
                        />,

                        renderAcoesColumn()

                    ]
                )
            }
        }

        const renderEmpresa = () => {
            if(this.props.unificar){
                return (
                    <Column
                        header="Empresa"
                        field="empresa.nome"
                        style ={ {width: '30px'} }
                        sortable
                        filter filterElement={nomeEmpresaFilterElement}
                    />
                )
            }
        }


        return (
            <div className="">

            <div className="card">

                <DataTable
                    ref={this.dt}
                    value={this.getList()}
                    className="p-datatable-sm small-font"
                    rowHover
                    selection={this.state.selectedNFs}
                    onSelectionChange={(e) => this.setState({selectedNFs: e.value})}
                    scrollable
                    scrollHeight={this.props.insideFichaDeEstoqueDetalhamento ? "300px" : "500px"}
                    loading={this.state.loading || this.props.loading}
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    expandableRows={true}
                    expandedRows = {this.state.expandedRows}
                    onRowToggle={this.handleRowToggle}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onValueChange={this.calculateQuantidadeAndValorTotal}
                    // showGridlines
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.getList() ? this.getList().length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Notas Fiscais"                    
                    >

                    <Column expander style={{ width: '20px' }} />

                    {renderColunaDadosPagamentoNF()}

                    {renderSimpleView()}

                    {renderQuantidadeProdutoNaNota()}
                    {renderConversao()}
                    {renderValorProdutoNaNota()}

                    {renderRemainderView()}

                    {renderEmpresa()}

                </DataTable>

                {renderMotivoDialog()}
                
                {renderNFDevolucaoEntradaDialog()}

            </div>

            <CheckNFDialog
                visible={this.state.checkNFDialogVisible}
                hideDialog={this.hideDialog}
                register={false}
                NFToValidate={this.state.NFToValidate}
                save={this.validateNF}
            />

            <ConfirmationDialog
                header={"Deletar Nota Fiscal"}
                visible={this.state.visibleConfirmDeleteNFDialog}
                confimationMessage="Deseja Deletar esta Nota Fiscal?"
                confirm={this.deleteNF}
                hide={() => this.setState({visibleConfirmDeleteNFDialog: false})}
                hasConfirmationMessage
                isMotivoDeleteNF
                placeholderConfirmationMessage="Motivo"
            />    

            <ConfirmationDialog
                header={"Reativar Nota Fiscal"}
                visible={this.state.visibleConfirmReativarNFDialog}
                confimationMessage="Deseja Revalidar esta Nota Fiscal?"
                confirm={this.revalidarNF}
                hide={() => this.setState({visibleConfirmReativarNFDialog: false})}
            />

            <EditProvisaoDialog
                visible={this.state.displayEditProvisaoDialog}
                // provisaoId={this.state.idProvsisaoToEdit}
                notaFiscal={this.state.notaFiscalToEditProvisao}
                search={this.props.search}
                startLoadingTable={this.props.startLoadingTable}
                finishLoadingTable={this.props.finishLoadingTable}
                hideDialog={this.hideEditProvisaoDialog}
            />              

        </div>
        )
    }


}  

export default TransactionByNFTable
