import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import HandleErrorService from '../../app/service/handleErrorService'
import AuthService from '../../app/service/authService'
import GeneralServices from "../../app/service/generalServices";
import CustomHighlighter from '../customHighlighter'
import EmpresaService from '../../app/service/central/empresaService'
import ConstantsUtil from '../../context/constantsUtil'
import TooltipButton from '../tooltipButton'
import LoginService from '../../app/service/loginService'
import * as popUp from '../toastr'
import SyncEmpresaNFsVendaDialog from './syncEmpresaNFsVendaDialog'
import CentralService from '../../app/service/central/centralService'
import NotificacaoService from '../../app/service/central/notificacaoService'

class EmpresasTable extends React.Component {

    constructor(){
        super()
        // this.grupoDeEmpresasService = new GrupoDeEmpresasService()
        this.empresaService = new EmpresaService()
        this.loginService = new LoginService()
        this.centralService = new CentralService()
        this.notificacaoService = new NotificacaoService()
    }

    state = {

        empresasList: [],
        loading: false,
        empresaToSyncNFVendas: {},
        displaySyncNFVendasDialog: false,

    }

    componentDidMount(){
        this.search()
    }

    search = () => {
        this.setState({loading: true})
        this.empresaService.getAllEmpresas()
        .then(response => {
            let empresasList = response.data
            if(this.props.onlyActive){
                empresasList = empresasList.filter(empresa => empresa.ativa)
            }
            this.setState({empresasList})
            this.setState({loading: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
        })
    }

    changeContextPath = (empresa) => {
        AuthService.changeCurrentContextPath(empresa.contextPath)
        window.location.reload()
    }

    filter(){
        return this.state.empresasList.filter(empresa => GeneralServices.includeString(empresa.nome, this.props.searchText))
    }

    editEmpresa = empresa => {
        this.props.edit(empresa)
    }

    openSyncNFVendasDialog = empresa => {
        this.setState({empresaToSyncNFVendas: empresa})
        this.setState({displaySyncNFVendasDialog: true})
    }

    changeStatusEmpresa = empresa => {
        this.setState({loading: true})
        this.empresaService.changeStatus(empresa)
        .then(response => {
            popUp.successPopUp(`Empresa ${empresa.ativa ? 'inativada' : 'ativada'} com sucesso!`)
            this.search()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    loginToEmpresa = empresa => {
        this.loginService.loginToEmpresa(empresa.tenantName)
        .then(response => {
            const data = response.data
            this.props.context.beginSession(data)
            window.location = "/home"
        }).catch(error => {
            console.log("error: ", error)
            HandleErrorService.handleError(error)
        })
    }

    conferePagamentosDia = (empresa) => {
        this.setState({loading: true})
        this.notificacaoService.conferePagamentosDia([empresa])
        .then(response => {
            popUp.successPopUp("Pagamentos conferidos com sucesso")
            this.search()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })        
    }

    isAdvancedView = () => {
        return this.props.context && this.props.context.perfil && this.props.context.perfil.isAdmin && !this.props.disableEditColumn
    }

    render() {

        const renderHighlightNome = (rowData) => {

            return(
                <CustomHighlighter
                    searchWords={[this.props.searchText]}
                    textToHighlight={rowData.nome}
                />
            )
        }

        const renderWorkerColumn = () => {
            if(this.isAdvancedView()){
                return (
                    <Column field="worker.contextPath" header="Worker" sortable
                        style ={ {width: '100px'} }
                    />
                )
            }
        }

        const actionBodyEntrar = (empresa) => {
            if(empresa.id === ConstantsUtil.idEmpresaGrupoSistema){
                return;
            }
            return (
                <React.Fragment>
                    <TooltipButton
                        tooltip = {`Trocar para ${empresa.nome}`}
                        icon="pi pi-sign-in"
                        onClick={() => this.loginToEmpresa(empresa)}
                    />
                </React.Fragment>
            );
        }

        const renderAcoesColumn = () => {
            if(this.isAdvancedView()){
                return (
                    <Column
                        header="Ações"
                        style ={ {width: '180px'} }
                        body={actionBody}
                    />   
                )
            }
        }

        const renderEditarEmpresaButton = (empresa) => {
            if(empresa.ativa)
            return (
                <TooltipButton
                    className={"p-button-rounded p-mr-2 p-button-success right-button"}
                    tooltip = "Editar Empresa"
                    tooltipOptions={{
                        className: 'custom-tooltip',
                        position: 'left'
                    }}          
                    onClick={() => this.editEmpresa(empresa)}
                />
                
            )
        }

        const renderChangeStatusEmpresaButton = (empresa) => {
            return(
                <TooltipButton
                    className={`p-button-rounded p-mr-2 right-button ${empresa.ativa ? 'p-button-danger' : ''}`}
                    icon = {empresa.ativa ? "pi pi-times" : "pi pi-check"}
                    tooltip = {
                        empresa.ativa ?
                        `Inativar Empresa.\nOs dados da empresa continuarão armazenados, mas não será permitido realizar login nela.\nPoderá ser reativada posteriormente.`
                        : `Ativar Empresa.`
                    }
                    tooltipOptions={{
                        className: 'custom-tooltip',
                        position: 'left'
                    }}                    
                    onClick={() => this.changeStatusEmpresa(empresa)}
                />
            )
        }

        const renderSyncVendas = (empresa) => {
            if(empresa.ativa)
            return(
                <TooltipButton
                    className={`p-button-rounded p-mr-2 right-button`}
                    icon = {"pi pi-link"}
                    tooltip = {"Configurar sincronização para importação de notas de venda com sistema emissor"}
                    tooltipOptions={{
                        className: 'custom-tooltip',
                        position: 'left'
                    }}                    
                    onClick={() => this.openSyncNFVendasDialog(empresa)}
                />
            )
        }

        const renderConferePagamentosDia = (empresa) => {
            if(empresa.ativa)
            return(
                <TooltipButton
                    className={`p-button-rounded p-mr-2 right-button p-button-warning`}
                    icon = {"pi pi-wallet"}
                    tooltip = {"Gerar notificações de pagamento para o dia atual"}
                    tooltipOptions={{
                        className: 'custom-tooltip',
                        position: 'left'
                    }}                    
                    onClick={() => this.conferePagamentosDia(empresa)}
                />
            )
        }

        const actionBody = (empresa) => {
            if(empresa.id === ConstantsUtil.idEmpresaGrupoSistema){
                return;
            }
            return (
                <React.Fragment>
                    {renderEditarEmpresaButton(empresa)}
                    {renderChangeStatusEmpresaButton(empresa)}
                    {renderSyncVendas(empresa)}
                    {renderConferePagamentosDia(empresa)}
                </React.Fragment>
            );
        }

        const renderSyncNFsVendaDialog = () => {
            if(this.state.displaySyncNFVendasDialog){
                return(
                    <SyncEmpresaNFsVendaDialog
                        visible={this.state.displaySyncNFVendasDialog}
                        empresa={this.state.empresaToSyncNFVendas}
                        hideDialog = {() => this.setState({displaySyncNFVendasDialog: false})}
                        search={this.search}
                    />
                )
            }
        }

        return (
            <div className="datatable-crud-demo">
            <div className="card">

                <DataTable
                    ref={this.dt}
                    value={this.filter()}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há Empresas cadastradas."
                    // selection={this.state.selectedContasBancaria}
                    // onSelectionChange={this.handleSelectionChange}
                    scrollable
                    // scrollHeight="500px"
                    loading={this.state.loading || this.props.loading}
                    dataKey="cnpj"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.filter() ? this.filter().length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Empresas"
                >

                    <Column field="nome" header="Nome" sortable
                        style ={ {width: '150px'} }
                        body = {renderHighlightNome}
                    />

                    <Column field="nomeSimplificado" header="Nome Simplificado" sortable
                        style ={ {width: '150px'} }
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.nomeSimplificado, rowIndex)}
                    />

                    <Column field="nomeGrupo" header="Grupo" sortable
                        style ={ {width: '100px'} }
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.nomeGrupo, rowIndex)}
                        />

                    <Column field="cnpj" header="CNPJ" sortable 
                        style ={ {width: '100px'} }
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.cnpj, rowIndex)}
                    />

                    <Column field="ativa" header="Ativa no Sistema" sortable
                        style ={ {width: '70px'} }
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.ativa ? 'Sim' : 'Não', rowData.ativa)
                        }}
                    />

                    <Column field="ativoNfStock" header="Ativo NFStock" sortable
                        style ={ {width: '70px'} }
                        // body={rowData => rowData.ativoNfStock ? 'Sim' : 'Não'}
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.ativoNfStock ? 'Sim' : 'Não', rowData.ativoNfStock)
                        }}                     
                    />

                    <Column field="syncApiNFsVenda" header="Sincronização com API de vendas"
                        sortable
                        style ={ {width: '70px'} }
                        // body={rowData => rowData.ativoNfStock ? 'Sim' : 'Não'}
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.syncApiNFsVenda ? 'Sim' : 'Não', rowData.syncApiNFsVenda)
                        }}                     
                    />

                    <Column field="nfstockLabel" header="Descrição no NFStock" sortable
                        style ={ {width: '100px'} }
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.nfstockLabel, rowIndex)}
                    />

                    {renderWorkerColumn()}

                    <Column
                        header="Entrar"
                        style ={ {width: '100px'} }
                        body={actionBodyEntrar}
                    />                    

                    {renderAcoesColumn()}

                </DataTable>

                {renderSyncNFsVendaDialog()}

            </div>

            </div>
        )
    }

}

export default EmpresasTable