class ConstantsUtil {

        static accessDeniedMessage = 'Usuário sem permissão.'

        static bootstrapInputErrorClass = 'is-invalid';
        static primereactInputErrorClass = 'p-invalid';

        static campoObrigatorioLabel = 'Campo Obrigatório'
        static preecnhaLabel = 'Preencha'

        static inputNumberSmallClass = "input-number-small small-font"

        static importacaoArquivoLancamentoInventario = "LANCAMENTO_INVENTARIO"
        static importacaoArquivoProdutosDeCompra = "CADASTRO_PRODUTOS_COMPRA"

        static tooltipCadastroManualProdutoCompra = 'Só utilize esta funcionalidade caso o produto não possa ser cadastrado através de importação de Nota Fiscal de compra.'

        static importacaoExtratoBancarioDescricao = "EXTRATO_BANCARIO"

        static virgulaEspaco = ", "
        static virgulaEspacoToRegexpSplit = /, (.*)/s

        static emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        static invalidEmailMessage = "Informe um email válido"
        static mensagemEnvioEmail = "Link para recuperação de senha enviado por email."

        static logoutPath = '/login/true/true'

        static usuarioAtivoLabel = "ATIVO"
        static systemUserId = -1

        static recoverPasswordPath = "/recoverPassword"

        static perfilAdministradorLabel = 'ADMINISTRADOR'
        static perfilOperadorLabel = 'OPERADOR'
        static perfilDiretorLabel = 'DIRETOR'

        static perfilList = 
        [
                {label: 'Selecione...', value: ''},
                {label:'Administrador', value: ConstantsUtil.perfilAdministradorLabel},
                {label:'Operacional', value: ConstantsUtil.perfilOperadorLabel},
                {label:'Diretoria', value: ConstantsUtil.perfilDiretorLabel},
        ]

        static perfilErrorMessage = 'Informe o perfil.'

        static contaContabilErrorMessage = 'Informe a conta crédito'
        static historicoErrorMessage = 'Informe o histórico'

        static informeDataErrorMenssage = "Informe a data"
        static informeValorErrorMenssage = "Informe o Valor"
        static informeQuantidadeErrorMenssage = "Informe a quantidade"

        static nenhumLancamentoEncontradoMessage = 'Nenhum Lançamento Encontrado'

        static naturezaTipoDeRecebimentoAutomaticoLabel = 'AUTOMATICO'
        static naturezaTipoDeRecebimentoBoletoLabel = 'BOLETO'
        static naturezaTipoDeRecebimentoAplicacaoFinanceiraLabel = 'APLICACAO_FINANCEIRA'
        static naturezaTipoDeRecebimentoDinheiroLabel = 'DINHEIRO'
        static naturezaTipoDeRecebimentoOutrosLabel = 'OUTROS'
        static naturezaTipoDeRecebimentoEstornoTarifaLabel = 'ESTORNO_TARIFA_BANCARIA'

        static tipoDePagamentoAplicacaoFinanceiraLabel = 'APLICAÇÃO FINANCEIRA'


        static avulso = 'AVULSO'
        static avulsoTipoDeRecebimentoLabel = this.avulso
        static avulsoTipoDePagamentoLabel = this.avulso
        static avulsoGrupoDePagamentoLabel = this.avulso
        static naoIdentificadoLabel = 'NÃO IDENTIFICADO'

        static nomeOutrasTarifasBancariasLabel = 'OUTRAS TARIFAS BANCÁRIAS'

        static tarifaBancaria = 'TARIFA BANCÁRIA'
        static tarifaBancariaTipoDePagamentoLabel = this.tarifaBancaria
        static tarifaBancariaGrupoDePagamentoLabel = this.tarifaBancaria

        static tipoDePagamentoNomeProperty = 'tipoDePagamento.nome'

        static escolherPeriodoLabel = 'Escolher período'

        static tiposDePagamentoNotAllowedToEdit =
        [
                this.avulsoTipoDePagamentoLabel,
                this.naoIdentificadoLabel,
                this.nomeOutrasTarifasBancariasLabel
        ]
        

        static aplicacacoesFinanceiras = 'APLICAÇÕES FINANCEIRAS'
        static aplicacacoesFinanceirasGrupoDePagamentoLabel = this.aplicacacoesFinanceiras

        static gruposDePagamentoNotAllowedToEdit =
        [
                this.avulsoGrupoDePagamentoLabel,
                this.tarifaBancariaGrupoDePagamentoLabel,
                this.aplicacacoesFinanceirasGrupoDePagamentoLabel,
                this.naoIdentificadoLabel
        ]

        static nomeTipoDeRecebimentoRendimentoAplicacaoAutomatica = 'RENDIMENTO DE APLICAÇÃO AUTOMÁTICA'

        static lancamentoBancarioRecebimentoLabel = 'RECEBIMENTO'
        static lancamentoBancarioRecebimentoAplicacaoFinanceiraLabel = 'RECEBIMENTO_REND_AP_AUTO_APLICACAO_FINANCEIRA'
        static lancamentoBancarioPagamentoLabel = 'PAGAMENTO'
        static tipoFichaBancariaLancamentoBancarioLabel = 'LANCAMENTO_BANCARIO'
        static lancamentoBancarioTransferenciaLabel = 'TRANSFERENCIA'
        static tipoFichaBancariaTransferenciaLabel = 'TRANSFERENCIA_BANCARIA'
        static lancamentoBancarioTransferenciaEntradaLabel = 'TRANSFERENCIA_ENTRADA'
        static lancamentoBancarioTransferenciaSaidaLabel = 'TRANSFERENCIA_SAIDA'
        static transferenciaRecebidaDeLabel = "Transferência Recebida de "
        static transferenciaRealizadaParaLabel = "Transferência Realizada para "
        static tipoTransacaoOFXCredit = 'CREDIT'
        static tipoTransacaoOFXDebit = 'DEBIT'

        static tipoDePagamentoAplicacaoFinanceiraInitialLabel = 'APLICAÇÃO FINANCEIRA'
        static tipoDePagamentoTarifa = 'TARIFA'
        static tipoLancamentoBancarioLabel = 'FECHAMENTO_BANCARIO'
        static tipoTotaisFichaBancariaLabel = 'TOTAIS'
        static tipoSaldoAnteriorFichaBancariaLabel = '"SALDO_ANTERIOR"'
        static totalSaldoAnteriorLabel = 'TOTAL SALDO ANTERIOR'
        static totalSaldoFinalLabel = 'TOTAL SALDO FINAL'
        static totaisTableLabel = 'totais'
        static totaisTableUpperCaseLabel = 'TOTAIS'
        

        static tipoTotaisFichaDeRecebimentoLabel = 'TOTAIS'
        static fichaDeRecebimentoSortTypeComissao = 'BY_COMISSAO'

        static resultadoNaoOperacionalLabel = 'RESULTADO'

        static resultadoOpracionalKey = 'RESULTADO_OPERACIONAL'
        static resultadoOpracionalLabel = "Resultado Operacional"

        static resultadoFinalKey = 'RESULTADO_FINAL'
        static resultadoFinalLabel = 'Resultado Final'

        static fichaBancaraiHeaderInDre = 'Lançamentos'

        static tipoDialogGrupoDePagamentoEditLabel = 'EDIT'
        static tipoDialogGrupoDePagamentoCreateLabel = 'CREATE'

        static errorTipoDeRecebimentoMessage = 'Informe o Tipo de Recebimento'

        static errorTurnoMessage = 'Informe o Turno'

        static calendarMesCompletoFilterLabel = 'mesCompleto'
        static calendarAnoCompletoFilterLabel = 'anoCompleto'
        static calendarPersonalizadoFilterLabel = 'personalizado'

        // static valueFormattedZero = 'R$ 0,00'
        static valueFormattedZero = '0,00'

        static valueFormattedLabel = 'R$'

        static destacadoNoDRECheckBoxLabel = 'Destacado no Fluxo de Caixa?'
        static ausenteNoDREPorCompetenciaCheckBoxLabel = 'Ignorar no DRE Por Competência?'
        static dreDestacadoEntrada = 'TIPO_DE_RECEBIMENTO'
        static dreDestacadoSaida = 'GRUPO_DE_PAGAMENTO'

        static tipoDePagamentoFiltroLabel = 'TIPO_DE_PAGAMENTO'

        static errorSelecioneEmpresasMessage = "Selecione as Empresas"

        static errorSelecioneContaBancariaMessage = "Selecione a Conta Bancária."

        static saldoInicialLabel = 'Saldo Inicial'
        static saldoFinalLabel = 'Saldo Final'
        static saldoNestaDataLabel = 'SALDO NESTA DATA'
        static saldoLabel = 'SALDO'
        static entradaLabel = 'ENTRADA'
        static saidaLabel = 'SAIDA'
        static lancamentoLabel = 'LANCAMENTO'

        static widthColumNomeDreConsolidado = '90px'
        static widthColumNomeSaldoDreConsolidado = '600px'
        static widthColumDreConsolidado = '50px'
        static widthColumDrePorcentagemConsolidado = '35px'
        static widthColumRelatorioFechamentoCaixa = '120px'
        static heightHeaderDREConsolidado = '15px'
        // static paddingBeetwenTablesDREConsolidado = '5px'
        static paddingBeetwenTablesDREConsolidado = '15px'

        static dataInicialAnteriorFinalMessage = 'Data inicial deve ser anterior à data final.'
        
        static initialTableQuantityOption = 100

        static initialRowsInNFtable = 15
        
        static tipoImplantacaoSaldoSelectList = [
    
                {label: 'Selecione...', value: ''},
                {label: 'Conta Bancária', value: 'CONTA_BANCARIA'},
                {label: 'Aplicação Financeira', value: 'APLICACAO_FINANCEIRA'},
        ]

        static nfAcrescimoValue = 'ACRESCIMO'
        static nfDescontoValue = 'DESCONTO'

        static acrescimoDescontoSelectList =
        [
                {
                    label: 'Acréscimo/Desconto?',
                    value: '',
                },
                {
                    label: 'Acréscimo',
                    value: this.nfAcrescimoValue,
                },
                {
                    label: 'Desconto',
                    value: this.nfDescontoValue,
                }
            ]

        static headerVersion = 'Version'
        static accessToken = 'accessToken'
        static extraHeaderObj = 'extraHeader'

        static exportXlsxLabel = "Exportar Em Excel"
        static exportPdfLabel = "Exportar Em PDF"

        /*
        Recursos do sistema
        */
       static fichaDePagamentoRecurso = 'FICHA_DE_PAGAMENTO'


        /*
        Estoque
        */
        static ajusteFichaDeEstoqueSobraClass = 'ajusteFichaDeEstoqueSobra' //valor em primereactTable.css
        static ajusteFichaDeEstoquePerdaClass = 'ajusteFichaDeEstoquePerda' //valor em primereactTable.css

        static selecioneOsProdutosMessage = 'Selecione os Produtos.'

        static tipoProdutoVendaLabel = 'VENDA'
        static tipoProdutoInsumoVendaLabel = 'INSUMO PARA VENDA'
        static tipoProdutoBonificacaoLabel = 'BONIFICAÇÃO'
        static tipoProdutoBonificacaoChave = 'BONIFICACAO'
        
        static tipoSyncNFsVendaTotvs = 'TOTVS'

        //Parametrizacao
        static situacaoParametrizacaJaParametrizado = "JA_PARAMETRIZADO"
        static situacaoParametrizacaAindaNaoParametrizado = "AINDA_NAO_PARAMETRIZADO"
        static situacaoParametrizacaONaoParametrizavel = "NAO_PARAMETRIZAVEL"

        //NFs
        static tipoNFEntradaLabel = 'ENTRADA'
        static tipoNFSaidaLabel = 'SAIDA'
        static tipoFichaDeEstoqueDevolucaoLabel = 'DEVOLUCAO'
        static tipoNFDevolucaoEntradaLabel = 'DEVOLUCAO_ENTRADA'
        static tipoNFDevolucaoSaidaLabel = 'DEVOLUCAO_SAIDA'

        static fonteNFUsuario = "USUARIO"

        static situacaoRegular = 'Documento_Regular'
        static situacaoRegularValidadaLabel = 'Validada'
        static situacaoRegularAguardandoValidacaoLabel = 'Aguardando Validação'

        static situacaoExcluida = 'Documento_Excluido'
        static situacaoExcluidaLabel = 'Excluída pelo Usuário'

        static situacaoCancelada = 'Documento_cancelado'
        static situacaoCanceladaLabel = 'Cancelada pelo Fornecedor'

        static filtroDataEmissaoLabel = 'DATA_EMISSAO'
        static filtroDataEntradaLabel = 'DATA_ENTRADA'
        static filtroDataNFOptionsList =
        [
                {
                        label: 'Selecione',
                        value: '',
                },
                {
                        label: 'Data de Emissão',
                        value: this.filtroDataEmissaoLabel,
                },
                {
                        label: 'Data de Entrada',
                        value: this.filtroDataEntradaLabel,
                },
        ]

        static sortDirectionAsc = 'asc'
        static sortDirectionDesc = 'desc'


        //DRE Competência
        static DRECompetenciaTipoEntradaLabel = 'ENTRADA'
        static DRECompetenciaTiposSaidaLabel = 'SAIDA'
        static DRECompetenciaTiposFormulaLabel = 'FORMULA'
        static DRECompetenciaGrupoReceitaBruta = "RECEITA_BRUTA"
        static DRECompetenciaGrupoDeducoesReceita = "DEDUCOES_RECEITA"
        static DRECompetenciaGrupoImpostos = "IMPOSTOS"
        static DRECompetenciaGrupoDespesasPessoal = "DESPESAS_PESSOAL"
        static DRECompetenciaGrupoDespesasFuncionamento = "DESPESAS_FUNCIONAMENTO"
        static DRECompetenciaGrupoOutrasDespesas = "OUTRAS_DEPESAS"
        static DRECompetenciaGrupoReceitaFinanceira = "RECEITA_FINANCEIRA"
        static DRECompetenciaItemReceitaAplicacoesFinanceiras = "RECEITA DE APLICAÇÕES FINANCEIRAS"
        static DRECompetenciaItemReceitaBonificacao = "RECEITA DE BONIFICAÇÃO"
        static DRECompetenciaGrupoCMV = "CMV"
        static provisoesDePagamentoHeaderLabel = "Provisões de Pagamento"
        static filtroProvisaoTipoDePagamentoId = "ID_TIPO_DE_PAGAMENTO"
        static filtroProvisaoTipoDePagamentoNome = "NOME_TIPO_DE_PAGAMENTO"
        static filtroProvisaoGrupoDePagamentoNome = "NOME_GRUPO_DE_PAGAMENTO"
        static origemProvisaoImportacaoNF = 'IMPORTACAO_NF'
        static origemProvisaoNFManual = 'NF_MANUAL'
        static origemProvisaoOutroasProvisoesManuais = 'OUTRAS_PROVISOES_MANUAIS'
        static receitaBrutaLabel = 'RECEITA BRUTA'


        //Notificações
        static pagamentoParaHojeChave = 'PAGAMENTO_PARA_HOJE'
        static checkProvisoesAPagarIsFromNotificacaoSessionStorageKey = 'checkProvisoesAPagarFromNotificacao'
        


        //Geral
        static objectLabel = {
                ENTRADA: 'Entrada',
                DEVOLUCAO_ENTRADA: 'Devolução de Entrada',
                SAIDA: 'Saída',
                DEVOLUCAO_SAIDA: 'Devolução de Saída',
        }
        
        static labelsToIgnoreInFilter =
        [
                this.tipoTotaisFichaBancariaLabel,
                this.saldoNestaDataLabel,
                this.totalSaldoAnteriorLabel,
                this.totalSaldoFinalLabel
        ]

        static dataLancamentoLabel = 'Data de Lançamento'
        static dataLancamentoValue = 'DATA_LANCAMENTO'

        static dataPagamentoLabel = 'Data de Pagamento'
        static dataPagamentoValue = 'DATA_PAGAMENTO'

        static componenteEscolherPeriodoId = 'selectedFilterType'

        static tipoFraseMotivoExclusaoNF = 'MOTIVO_EXCLUSAO_NF'

        //IdsFixos
        static idEmpresaGrupoSistema = 1

        //pathanmes
        static historicoImportacaoNFsPathname = '/historicoImportacaoNFs'
        static syncImportacaoNFsVendaPathname = '/syncNFsVenda'
        static homePathname = '/home'

}

export default ConstantsUtil