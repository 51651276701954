import React from 'react';
import Routes from './routes'

import 'toastr/build/toastr.min.js'
import '../css/_variables.scss'
import '../css/_bootswatch.scss'
import '../css/bootstrap.min.css'
import '../css/bootstrap.css'
import '../css/custom.css'
import '../css/primereactTable.css'
import '../css/visaorj.scss'
import 'toastr/build/toastr.css'

import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import AuthenticationProvider from './authProvider'
import MenuModulesbar from '../components/topbar/menuModulesBar';
import UseServiceWorker from './useServiceWorker';
import { Button } from 'primereact/button';
import BottomBar from '../components/bottomBar';
import AuthService from '../app/service/authService';
import { CustomThemeMUIProvider } from './muiThemeProvider';
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import GeneralServices from '../app/service/generalServices';

class App extends React.Component {

    constructor(){
      super()
      this.useServiceWorkerRef = React.createRef()
      this.generalServices = new GeneralServices()
    }
  
    state = {
      displayButton: false,
      blockUi: false,
    }

    componentDidMount(){
      AuthService.stopBlockUi()
      this.checkBlockUi()
    }

    checkBlockUi = async () => {
      await this.generalServices.sleep(100)
      let blockUi = AuthService.isBlockUi()
      if(blockUi !== this.state.blockUi){
        await this.setState({blockUi})
      }
      this.checkBlockUi()
    }

    callRefresh= () => {
      // console.log("env: ", process.env.NODE_ENV)
      // console.log("ShowReload: ", this.useServiceWorkerRef.current.getShowReload())
      // console.log("getWaitingWorker: ", this.useServiceWorkerRef.current.getWaitingWorker())
      // console.log("displayButton")
      this.setState({displayButton: true})
    }

    refresh = () => {
      this.useServiceWorkerRef.current.reloadPage()
      this.setState({displayButton: false})
    }

  render(){

    const renderButton = () => {
      if(this.state.displayButton){
        return (
          <Button
            label= "Refresh"
            onClick={this.refresh}
         />
        )
      }
    }

    const renderLoading = () => {
      if(this.state.blockUi){
        return (
          <div className="block-overlay">
              <div className="block-content">
                  <ProgressSpinner
                    style={{ width: '50px', height: '50px' }}
                    strokeWidth="5"
                    // animationDuration="1s"
                  />
                  <p>Carregando, por favor aguarde...</p>
              </div>
          </div>
        )
      }
    }

    return(
    <div>
      
      <BlockUI blocked={this.state.blockUi} fullScreen />

      {renderLoading()}

      <div
        className = "body-color"
        style={
          {minHeight: '100vh'}
        }     
      >
      <AuthenticationProvider>
        <UseServiceWorker ref={this.useServiceWorkerRef} />
        <MenuModulesbar callRefresh={this.callRefresh} />
        {renderButton()}
        
        <CustomThemeMUIProvider>
          <Routes />
        </CustomThemeMUIProvider>
        
        <br />
        <br />
        <br />
        <BottomBar
          loggedInfo={AuthService.isAuth()}
        />
      </AuthenticationProvider>
      </div>

        </div>

    );
  }
}

export default App;
